@import '../../theme/colors';
@import '../../theme/breakpoints';

.csvSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  @include bp-min-md {
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}

.csvSectionTitle {
  font-size: 18px;
  padding: 0;
  white-space: nowrap;
}

.csvSectionSelectContainer {
  width: 320px;
}
